import styled from "styled-components";

export const Wrapper = styled.div`
  --linkColor: firebrick;
  --backgroundColor: #fcfcff;
  --fontSize: 20px;
  --lineHeight: 36px;
  background-color: var(--backgroundColor);
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
  padding: var(--lineHeight);
  font-family: var(--serifFont);
  & a {
    color: var(--linkColor);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 767px) {
    --fontSize: 16px;
    --lineHeight: 24px;
    padding: var(--lineHeight) 8px 8px 8px;
  }
`;

export const HeaderDiv = styled.header`
  text-align: center;
  padding-bottom: var(--lineHeight);
  margin-bottom: var(--lineHeight);
  border-bottom: 4px solid var(--linkColor);
  & h1 {
    margin: 0;
  }
`;

export const Footer = styled.footer`
  font-family: var(--sansSerifFont);
  padding-top: var(--lineHeight);
  margin-top: var(--lineHeight);
  border-top: 4px solid var(--linkColor);
  display: flex;
  justify-content: center;
  & a {
    margin: 0 var(--lineHeight);
  }
`;
